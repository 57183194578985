<template>
  <div class="kids">
    <div class="title">{{ $t("promotekid.default[0]") }}</div>
    <div class="head">
      <div class="head_title">{{ $t("promotekid.default[1]") }}</div>
    </div>
    <div class="body">
      <div class="body_border">
        <div class="body_word" v-html="$t('promotekid.default[2]')"></div>
      </div>
    </div>
    <button>
      <a :href="`${CurrLine}/#/register/${appRegister}`">{{
        $t("promotekid.default[3]")
      }}</a>
    </button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      CurrLine: "", //当前域名
    };
  },
  props: ["appRegister"],
  created() {
    this.CurrLine = localStorage["CurrLine"];
  },
  methods: {},
};
</script>
<style scoped>
.kids {
  background-image: url("../../../public/static/images/promote_bg.png");
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.title {
  width: 100%;
  height: 50px;
  line-height: 50px;
  color: #fff;
  position: fixed;
  font-size: 18px;
  top: 0;
  text-align: center;
}

.head {
  width: 100%;
  position: relative;
  top: 60px;
  z-index: 9;
}

.head_title {
  width: 320px;
  height: 110px;
  text-align: center;
  font-size: 30px;
  line-height: 110px;
  background-image: url("../../../public/static/images/title.png");
  background-size: 320px 110px;
  background-position-x: -1.5px;
  color: #4e51bf;
  font-weight: 600;
  font-size: 22px;
  font-style: italic;
  text-align: center;
  line-height: 100px;
  margin: auto;
}

.body {
  position: absolute;
  top: 160px;
  width: 100%;
}

.body_border {
  background-image: linear-gradient(to bottom, #6058bc, #fe93a4);
  width: 248px;
  height: 220px;
  margin: auto;
  padding: 3px;
  border-radius: 5px;
}

.body_word {
  height: 100%;
  padding: 20px;
  background: #fff;
  text-align: center;
  line-height: 25px;
  color: #4e51bf;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 2px;
}

.body_word span {
  color: #fe93a4;
  font-size: 16px;
}

button {
  margin: auto;
  margin-top: 320px;
  width: 100px;
  width: 150px;
  height: 40px;
  border-radius: 15px;
  border: none;
  box-shadow: #fe889d 0px 4px 1px 1px;
  background-image: linear-gradient(to right, #ffe9ce, #b3b4f8);
  opacity: 0.8;
}

a {
  color: #4e51bf;
  font-size: 18px;
  font-weight: 600;
}
</style>
